import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About RISCV' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About RISC-V'
            subheader='RISC-V is an open standard Instruction Set Architecture (ISA) enabling a new era of processor innovation through open collaboration.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            {/* <Button as={GLink} to='/contact' sx={styles.button}>
              Contact Me
            </Button> */}
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='RISC-V.ORG'>
                <Text variant='p'>
                  <i>(The following is taken from the riscv.org website)</i>
                </Text>
                <Text variant='p'>
                  RISC-V enables the community to share technical investment,
                  contribute to the strategic future, create more rapidly, enjoy
                  unprecedented design freedom, and substantially reduce the
                  cost of innovation.
                </Text>
                <Text variant='p'>
                  More than 3,100 RISC-V members across 70 countries contribute
                  and collaborate to define RISC-V open specifications as well
                  as convene and govern related technical, industry, domain, and
                  special interest groups.
                </Text>
                <Text variant='p'>
                  RISC-V combines a modular technical approach with an open,
                  royalty-free ISA — meaning that anyone, anywhere can benefit
                  from the IP contributed and produced by RISC-V. As a
                  non-profit, RISC-V does not maintain any commercial interest
                  in products or services. As an open standard, anyone may
                  leverage RISC-V as a building block in their open or
                  proprietary solutions and services.
                </Text>
                <Text variant='p'>
                  RISC-V does not take a political position on behalf of any
                  geography. We are proud to see organizations from around the
                  world working together in this new era of processor
                  innovation. RISC-V was founded in 2015 as the RISC-V
                  Foundation and is incorporated today as RISC-V International
                  Association in Switzerland.
                </Text>
                <Text variant='p'>
                  For more information please go to{' '}
                  <GLink href='http://riscv.org'>RISC-V International</GLink>
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Start Developing Now'>
                <Card variant='paper'>
                  <GLink href='./building-a-developer-environment-for-riscv64'>
                    How to set up a riscv64 development environment.
                  </GLink>
                </Card>
              </Section>
              <Divider />
              <Section title='Hardware Available'>
                <Card variant='paper'>
                  Details on boards currently available coming soon!.
                </Card>
              </Section>
              <Divider />
              <Section title='New Hardware Coming Soon'>
                <Card variant='paper'>
                  Details on new boards in development coming soon!.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
